@tailwind base;
@tailwind components;
@tailwind utilities;


.btn {
  @apply inline-flex items-center rounded-md border border-transparent
  bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
  focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm
}

.fade-right::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 150px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 1));
  pointer-events: none;
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


.loader {
    border: 10px solid #1d2737;
    border-top: 10px solid transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
